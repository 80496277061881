import { Image } from 'components/01-atoms/Image';
import { Button } from 'components/01-atoms/Button';
import { Icon } from 'components/01-atoms/Icon';
import { useRouter } from 'next/router';

type Error404Props = {
  routes: {
    home: () => string;
  };
};

const Error404 = ({ routes }: Error404Props) => {
  const router = useRouter();

  return (
    <div className="relative h-full w-full">
      <Image
        src="https://wp.watch.outsideonline.com/wp-content/uploads/2021/12/error404-scaled.jpg"
        alt="Error Page Image"
        classes="object-cover min-h-[720px]"
      />
      <div className="absolute top-1/2 -mt-20 -translate-y-1/2 transform pl-10 pr-10 text-center narrow:left-1/2 narrow:-translate-x-1/2">
        <h2 className="mb-4 text-60 text-network-white narrow:mb-10 narrow:text-8xl">
          Oops!
        </h2>
        <p className="text-20 text-network-white narrow:mb-5">
          The page you&apos;re looking for wasn&apos;t found. Let&apos;s guide
          you back.
        </p>
        <Button
          theme="secondary"
          size="sm"
          icon={
            <Icon
              color="#000"
              type="refresh"
              size="small"
              classes="mr-3 opacity-20"
            />
          }
          classes="pl-10 pr-10 pt-4 pb-3 mt-5 font-bold bg-network-outside-yellow text-black flex items-center"
          type="button"
          text="Back to Home"
          onClick={() => router.push(routes.home())}
        />
      </div>
    </div>
  );
};

export default Error404;
